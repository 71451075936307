import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import { catchAsync, formatNumber } from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { IresMediaCmsHub } from "api/types/_cmsHub";
import ButtonMediaAccess from "../Components/ButtonGotoMedia";
import MediaModal from "pages/Media/Media/ModalMedia";
import DropdownOption from "components/Common/DropdownOption";
import { BUSSINESS_TEAM_LANG } from "helpers/constans";
import MediaApi from "api/mediaApi";

const MediaTab = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    business_team_id: "",
  };
  const [media, setMedia] = useState<any>(null);
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    queryParams?.start_date
      ? moment(queryParams?.start_date || "", "Y-MM-DD").toDate()
      : null,
    queryParams?.end_date
      ? moment(queryParams?.end_date || "", "Y-MM-DD").toDate()
      : null,
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresMediaCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [business, setBusinessTeams] = useState<any>([]);

  const BUSSINESS = useMemo(() => {
    const arr: any =
      business?.map((item: any) => ({
        value: item.id,
        label: t(BUSSINESS_TEAM_LANG[item.id]),
      })) || [];
    return [
      {
        value: "",
        label: t("cms_media.all"),
      },
      ...arr,
    ];
  }, [business, t]);

  const businessTeams = async () => {
    const [err, result] = await catchAsync(MediaApi.businessTeams());

    if (err) {
      console.log(err);
      return;
    }
    setBusinessTeams(result?.data);
  };

  // Column
  const columns = useMemo(() => {
    const renderNumber = (original: IresMediaCmsHub) => (
      <span>{formatNumber(original?.total_articles)}</span>
    );
    const renderPV = (original: IresMediaCmsHub) => (
      <span>{formatNumber(original?.pv)}</span>
    );
    const renderUV = (original: IresMediaCmsHub) => (
      <span>{formatNumber(original?.uv)}</span>
    );
    const renderTops = (original: IresMediaCmsHub) => (
      <div className="d-flex gap-4 text-center">
        {original?.tops?.map((item) => (
          <div className="d-flex flex-column gap-2" key={item?.id}>
            <div>{item.name}</div>
            <div>{formatNumber(item.total_articles)}</div>
          </div>
        ))}
      </div>
    );

    return [
      {
        Header: t("cms_media.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_media.media_name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <div className="w-100 text-center cursor-pointer">
            <p className="m-0" onClick={() => setMedia(cell?.row?.original)}>
              <u>{cell?.value}</u>
            </p>
          </div>
        ),
      },
      {
        Header: t("media.business"),
        accessor: "business_team.id",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{t(BUSSINESS_TEAM_LANG[cell?.value])}</p>
          </div>
        ),
      },
      {
        Header: t("cms_media.number_of_published_article"),
        accessor: "total_articles",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{renderNumber(row.original)}</div>
        ),
      },
      {
        Header: t("cms_media.PV"),
        accessor: "pv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">{renderPV(row.original)}</div>
        ),
      },
      {
        Header: t("cms_media.UV"),
        accessor: "uv",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => (
          <div className="text-center">{renderUV(row.original)}</div>
        ),
      },
      {
        Header: t("cms_media.AverageUV"),
        accessor: "avg_uv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ value }: any) => (
          <div className="text-center">{formatNumber(value || 0)}</div>
        ),
      },
      {
        Header: t("cms_media.AveragePV"),
        accessor: "avg_pv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ value }: any) => (
          <div className="text-center">{formatNumber(value || 0)}</div>
        ),
      },
      {
        Header: t("cms_media.top_3_most_published"),
        accessor: "top_s_most_published",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => renderTops(row.original),
      },
      {
        Header: "",
        accessor: "action",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => (
          <ButtonMediaAccess id={row?.original?.id} redirect="/app" />
        ),
      },
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    const [err, result] = await catchAsync(
      cmsHubMediaApi.cmshubCompanies(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    const startDateTmp = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : "";
    const endDateTmp = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDateTmp,
      end_date: endDateTmp,
    });
  };

  const handleChangePage = useCallback(
    (page: any) => {
      setQueryParams({
        ...queryParams,
        search: keyword,
        page: page + 1,
      });
    },
    [queryParams]
  );

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  useEffect(() => {
    businessTeams();
  }, []);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
            <div className="w-100">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div className="d-flex flex-wrap gap-3">
                    <div
                      className="d-flex align-items-center date-picker-wrapper-custom"
                      style={{ gap: "40px" }}
                    >
                      <div className="fw-bold text-nowrap">
                        {t("cms_media.period")}
                      </div>
                      <div style={{ width: "300px" }}>
                        <DatePickerCustom
                          placeholder={`${t("A From Range")} - ${t(
                            "A To Range"
                          )}`}
                          startDate={startDate || null}
                          endDate={endDate || null}
                          disabled={false}
                          isClearable={true}
                          onChangePicker={handleChangePicker}
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center date-picker-wrapper-custom"
                      style={{ gap: "40px" }}
                    >
                      <div className="fw-bold text-nowrap">
                        {t("media.business")}
                      </div>
                      <div style={{ width: "200px" }}>
                        <DropdownOption
                          name="business_team_id"
                          dataList={BUSSINESS}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                          initialValue={BUSSINESS?.find(
                            (i: any) =>
                              i?.value == queryParams?.business_team_id
                          )}
                          onChangeSelect={(e: any) => {
                            if (e) {
                              setQueryParams({
                                ...queryParams,
                                page: 1,
                                business_team_id: Number(e?.value),
                              });
                            }
                          }}
                          labelTop={""}
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div
                    className="d-flex align-items-center date-picker-wrapper-custom"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">
                      {t("cms_media.period")}
                    </div>
                    <div style={{ width: "200px" }}>
                      <DropdownOption
                        name="business_team_id"
                        dataList={BUSSINESS}
                        className="search-filter-category-type"
                        classNamePrefix="name-prefix"
                        initialValue={BUSSINESS?.find(
                          (i: any) => i?.value == queryParams?.business_team_id
                        )}
                        onChangeSelect={(e: any) => {
                          if (e) {
                            setQueryParams({
                              ...queryParams,
                              page: 1,
                              business_team_id: Number(e?.value),
                            });
                          }
                        }}
                        labelTop={""}
                      />
                    </div>
                  </div>
                </Col> */}
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">
                      {t("cms_media.search")}
                    </div>
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder={`${t("cms_media.input_search")}...`}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchCategory();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                >
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSearchCategory}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
      <MediaModal
        media={media}
        onClose={() => setMedia(false)}
        onRefresh={() => paging()}
      />
    </React.Fragment>
  );
};

export default MediaTab;
